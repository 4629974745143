import { createSlice } from '@reduxjs/toolkit';
import { getAllTypes, getTypeById } from './typeThunk';
const initialState = {
    allTypes: [],
    oneData: undefined,
    type: [],
    loading: false,
    error: null,
    allTypesStatus: 'idle',
    total: 0,
};
const slice = createSlice({
    name: 'type',
    initialState,
    reducers: {
        clearOneData: (state) => {
            state.oneData = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllTypes.pending, (state) => {
            state.allTypesStatus = 'loading';
            state.loading = true;
        })
            .addCase(getAllTypes.fulfilled, (state, { payload }) => {
            state.allTypesStatus = 'succeeded';
            state.allTypes = payload.docs;
            state.loading = false;
            state.total = payload.meta.totalDocs;
        })
            .addCase(getAllTypes.rejected, (state) => {
            state.allTypesStatus = 'failed';
            state.loading = false;
        })
            .addCase(getTypeById.pending, (state, actions) => {
            state.loading = true;
        })
            .addCase(getTypeById.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.oneData = payload?.data?.data;
        })
            .addCase(getTypeById.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        });
    },
});
// Reducer
export default slice.reducer;
// Actions
export const { clearOneData } = slice.actions;
